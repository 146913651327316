import React from 'react'
import BOOKING_LINK from '../../../js/BOOKING_LINK'

const Questions = () => {
  return (
    <div className="background-colour-beige-4">
      <div className="container py-4">
        <h3 className="question">Can I book online?</h3>
        <p>
          <b>Yes!</b> We offer Online Bookings whether you are a regular salon
          guest or needing a last minute appointment.
        </p>
        <p>
          Alternatively you can call us on 0493242852 or email us at
          info@littlehairlounge.com.au
        </p>
        <a
          href={BOOKING_LINK}
          rel="noreferrer"
          target="_blank"
          className="links"
        >
          Online Bookings Here
        </a>

        <h3 className="question">What are your operating hours?</h3>
        <p>Monday: Closed</p>
        <p>Tuesday-Saturday: By Appointment</p>
        <p>Sunday: Closed</p>

        <h3 className="question">How to prepare for my appointment?</h3>
        <p>
          Please aim to arrive 5–10 minutes prior to your scheduled appointment
          time. Be sure to bring photos of your desired look. This will help me
          understand what you are wanting to achieve. For colour appointments,
          please arrive with unwashed hair.
        </p>

        <h3 className="question">What about COVID safety?</h3>
        <p>
          The safety of our salon guests is paramount. Between clients the salon
          is thoroughly cleaned and disinfected. Please wear a mask and santise
          when you arrive.
        </p>
        <p>
          If you are unwell, please cancel your appointment with 24hrs notice.
          If you attend your appointment and are visibly unwell, we reserve the
          right to turn you away. We want to avoid the spread of illness within
          our salon to our staff and guests.
        </p>

        <h3 className="question">What is your cancellation policy?</h3>
        <p>
          We require a minimum of 24hrs notice for all appointment cancellations
          or changes. If the appointment has been cancelled or rescheduled with
          less than 24hrs notice you will incur a $50 cancellation fee.
        </p>

        <h3 className="question">What is your return and resolve policy?</h3>
        <p>
          At Little Hair Lounge we want all our clients to leave happy and
          excited about the result of their hair. If you are unhappy with your
          hair, please let us know immediately prior to leaving the salon. On
          the rare occasion that you are unhappy with your hair after leaving
          the salon, we offer a one week Return & Resolve Policy.
        </p>
        <p>
          Please make contact with us by calling or texting 0493242852 with the
          details of your concern. We will then arrange a time for you to return
          to the salon for a consultation to assess your hair in person. Please
          do not attend another salon to have any other treatments/colour
          applied to your hair prior to our team assessing your hair. Once your
          concerns have been assessed we will offer you the best possible path
          to resolve your issues.
        </p>
      </div>
    </div>
  )
}

export default Questions
