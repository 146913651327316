import React from 'react'
import Layout from '../layout/Layout'
import ContactUsCta from './components/faqs/ContactUsCta'
import Questions from './components/faqs/Questions'
import Footer from './components/shared/Footer'
import NavBar from './components/shared/NavBar'

const FaqPage = () => {
  return (
    <Layout>
      <NavBar />
      <div className="page-header-background-image page-header-faq-background-image">
        <div className="container py-5 text-center">
          <h1 className="p-5 text-center">Frequently Asked Questions</h1>
        </div>
      </div>
      <div className="leather-divider"></div>
      <Questions />
      <ContactUsCta />
      <Footer />
    </Layout>
  )
}

export default FaqPage
